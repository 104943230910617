<template>
  <div class="work">
    <div class="container is-max-desktop">
      <div class="py-6">
        <p class="title is-1 has-text-centered has-text-black">Miniwiki</p>
        <nav
          class="breadcrumb is-small is-centered"
          aria-label="breadcrumbs"
        >
          <ul>
            <li><a @click="$router.push('/work')">other works</a></li>
            <!-- <li><a href="/work">work</a></li> -->
            <li class="is-active">
              <a href="#" aria-current="page">Miniwiki</a>
            </li>
          </ul>
        </nav>
      </div>
      <!-- <div class="markdown-body">
        <Demo />
      </div> -->
    </div>

    <!-- Cover Image -->
    <div class="container is-max-widescreen">
      <figure class="image">
        <img class="img-border" src="img/works/088/cover.jpg" />
      </figure>
    </div>

    <div class="my-6" />

    <!-- Product Overview -->
    <div class="container is-fluid py-6">
      <div class="container is-max-widescreen">
        <p class="title is-2 has-text-weight-bold has-text-black">
          Project Overview
        </p>
        <hr />
        <div class="tile is-ancestor">
          <div class="tile is-parent is-vertical">
            <article class="tile is-child">
              <p class="heading is-size-6 has-text-weight-bold has-text-black">
                Product Description
              </p>
              <div class="content">
                <p>
                  Miniwiki is a conceptual Text-only UI application for
                  distraction-free wiki reading. A Text-only appoften provides
                  no UI at all or a very minimalistic UI, which allows users to
                  focus more on the content itself, allowing users to get the
                  information they need faster and better, making them more
                  effective.
                </p>
              </div>
            </article>
            <article class="tile is-child">
              <p class="heading is-size-6 has-text-weight-bold has-text-black">
                Responsibility
              </p>
              <div class="content">
                <p>
                  Create Text-only UI for a distraction-free wiki reading app.
                  Build UX prototypes for it as well.
                </p>
              </div>
            </article>
          </div>
          <div class="tile is-vertical">
            <div class="tile">
              <div class="tile is-parent is-vertical">
                <article class="tile is-child">
                  <p
                    class="
                      heading
                      is-size-6
                      has-text-weight-bold has-text-black
                    "
                  >
                    Goal
                  </p>
                  <div class="content">
                    <p>
                      Complete the interaction and page design challenges of
                      Text-only UI App, and consider the integrity and usability
                      of the product as much as possible
                    </p>
                  </div>
                </article>
                <article class="tile is-child">
                  <p
                    class="
                      heading
                      is-size-6
                      has-text-weight-bold has-text-black
                    "
                  >
                    Role
                  </p>
                  <div class="content">
                    <p>UI/UX design</p>
                  </div>
                </article>
              </div>
              <div class="tile is-parent is-vertical">
                <article class="tile is-child">
                  <p
                    class="
                      heading
                      is-size-6
                      has-text-weight-bold has-text-black
                    "
                  >
                    Duration
                  </p>
                  <div class="content">
                    <p>2 Week</p>
                  </div>
                </article>
                <article class="tile is-child">
                  <p
                    class="
                      heading
                      is-size-6
                      has-text-weight-bold has-text-black
                    "
                  >
                    Tools
                  </p>
                  <div class="content">
                    <p>Skech, XD</p>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="my-6" />

    <!-- Text-only Design -->
    <div class="container is-fluid py-6">
      <div class="container is-max-widescreen">
        <p class="title is-2 has-text-weight-bold has-text-black">
          Text-only UI Design
        </p>
        <hr />

        <div class="tile is-ancestor">
          <div class="tile is-parent is-vertical is-4">
            <article class="tile is-child">
              <p class="subtitle has-text-weight-bold has-text-black">
                Challenges Overview
              </p>
            </article>
          </div>
          <div class="tile is-parent is-vertical">
            <article class="tile is-child">
              <div class="content">
                <p
                  class="heading is-size-6 has-text-weight-bold has-text-black"
                >
                  Why do this challenge?
                </p>
                <p>
                  The purpose is to find out how text typography is important in
                  App design
                </p>
                <p>
                  Imagine an app without any text at all? Not realistic. Also,
                  imagine if the page only has text, will the product be used
                  well without the dynamic and colorful pictures and videos? Can
                  an effective UI and UX be achieved?
                </p>
                <p>
                  Although pictures and videos are very dynamic and colorful,
                  and also have a very rich information transmission function,
                  users still need to obtain information through text. It's not
                  just a habit, words themselves can convey information that
                  other elements cannot. Excellent mobile phone typesetting
                  design will not cause users to have visual fatigue, but should
                  allow users to easily obtain information and achieve effective
                  human-computer interaction.
                </p>
              </div>
              <p class="heading is-size-6 has-text-weight-bold has-text-black">
                To complete this design challenges, need to
              </p>
              <div class="content">
                <ul>
                  <li>
                    Make sure the UI of the entire app does not contain any
                    images or icons but texts
                  </li>
                  <li>
                    Increase text size and weight: improving readability, in
                    this text-only layout design
                  </li>
                  <li>
                    Keep user experience and user process of this text-only app
                    as clear, smooth and consistance as possible
                  </li>
                </ul>
              </div>
            </article>
          </div>
        </div>

        <div class="my-6" />

        <div class="tile is-ancestor">
          <div class="tile is-parent is-vertical">
            <article class="tile is-child">
              <p class="subtitle has-text-weight-bold has-text-black">
                UX flow Design
              </p>
            </article>
            <article class="tile is-child">
              <figure
                class="image"
                @click="handleOpenActiveLightBox('img/works/088/ux-0.jpg')"
              >
                <img class="img-border" src="img/works/088/ux-0.jpg" />
              </figure>
            </article>
            <article class="tile is-child">
              <video width="100%" autoplay muted loop>
                <source src="img/works/088/demo.mp4" type="video/mp4" />
              </video>
            </article>
          </div>
        </div>

        <div class="my-6" />

        <div class="tile is-ancestor">
          <div class="tile is-parent is-vertical">
            <article class="tile is-child">
              <p class="subtitle has-text-weight-bold has-text-black">
                UI Design
              </p>
            </article>

            <article class="tile is-child">
              <figure
                class="image"
                @click="handleOpenActiveLightBox('img/works/088/ui-0.jpg')"
              >
                <img class="img-border" src="img/works/088/ui-0.jpg" />
              </figure>
            </article>
            <article class="tile is-child">
              <figure
                class="image"
                @click="handleOpenActiveLightBox('img/works/088/ui-1.jpg')"
              >
                <img src="img/works/088/ui-1.jpg" />
              </figure>
              <!-- <p class="subtitle is-7 has-text-centered py-3">
                Click on image to enlarge
              </p> -->
            </article>
            <article class="tile is-child">
              <figure
                class="image"
                @click="handleOpenActiveLightBox('img/works/088/ui-2.jpg')"
              >
                <img src="img/works/088/ui-2.jpg" />
              </figure>
            </article>
            <article class="tile is-child">
              <figure
                class="image"
                @click="handleOpenActiveLightBox('img/works/088/ui-3.jpg')"
              >
                <img src="img/works/088/ui-3.jpg" />
              </figure>
            </article>

          </div>
        </div>

        <!-- <div class="tile is-ancestor">
          <div class="tile is-parent is-vertical">
            <article class="tile is-child">
              <p>
                PAIN POINT We’re creating a Zoé Coffee shop app to make
                consumption more convenient for consumers with this online
                system. We want to increase sales, provide more efficient
                services and enhance user satisfaction, branding out our own
                coffee shop
              </p>
            </article>
          </div>
        </div> -->
      </div>
    </div>

    <!-- Image light box modal -->
    <div class="modal" :class="{ 'is-active': isLightBoxActive }">
      <div class="modal-background"></div>
      <div class="modal-content">
        <figure class="image">
          <img :src="lightBoxImageSrc" />
        </figure>
      </div>
      <button
        class="modal-close is-large"
        aria-label="close"
        @click="handleCloseActiveLightBox"
      ></button>
    </div>

    <!-- Back to top -->
    <back-to-top bottom="50px" right="50px">
      <button class="button is-normal is-responsive">
        <span class="icon has-text-weight-bold has-text-black">UP</span>
      </button>
    </back-to-top>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
// import VueMarkdown from 'vue-markdown-render';
// import Demo from "./demo.md";

// 代码高亮
// import "highlight.js/styles/github.css";
// 其他元素使用 github 的样式
// import "github-markdown-css";

export default {
  name: "work",
  components: {
    Navbar,
  },
  data() {
    return {
      isLightBoxActive: false,
      lightBoxImageSrc: "",
    };
  },
  mounted() {
    this.track();
  },
  methods: {
    handleOpenActiveLightBox(src) {
      this.lightBoxImageSrc = src;
      this.isLightBoxActive = true;
    },
    handleCloseActiveLightBox() {
      this.lightBoxImageSrc = "";
      this.isLightBoxActive = false;
    },
    toIndexList() {
      // this.$router.push('/');
      // this.$router.reload();
      window.location.reload();
    },
    track() {
      this.$gtag.pageview("/miniwiki");
      this.$gtag.query('event', 'screen_view',{
        app_name: 'juliandesign.io',
        screen_name: 'Miniwiki',
      })
    }
  }
};
</script>

<style scoped>
@media screen and (min-width: 769px) {
  .modal-content,
  .modal-card {
    width: 80%;
  }
}
</style>
